import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesBay: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Bay guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_bay.webp"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Bay guide & review</h1>
          <h2>A guide & review for Bay in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>14/04/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <StaticImage
          src="../../../images/nikke/generic/banner_bay.webp"
          alt="Bay"
        />
        <p>
          <strong>Bay</strong> banner will be available:{' '}
          <strong>4/11 After Maintenance ~ 4/24 23:59 (UTC+9)</strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="bay" enablePopover />
        </div>
        <p>
          If Noah is the ultimate answer to all Nuke in Nikke, then we have Bay,
          the cheaper copy of Noah. Tetra's latest Nikke, a cheerleader with
          scopophobia, is a defending cheerleader instead of a supporting one.
          Let's go through her kit together to see how Bay overcomes all her
          weaknesses to provide the loudest cheer for the commander in battle!
        </p>
        <SectionHeader title="Summary" />
        <h5>Should I pull this unit?</h5>
        <p>
          Skip if you are not interested in PvP. However, if you have enough
          mileage (200-400 gold tickets) saved up for half anniversary, we think
          the answer is Yes, you can spend your pulls on her banner, but only if
          you care about PvP. One copy is enough. Not only is she the cheaper
          Noah, but also you can pretty much throw her in your team with 0
          investment and she will do just fine at 1/1/1 and no OL. Her synergy
          with multiple units is highly appreciated for PvP.
        </p>
        <p>
          However, if you do not have enough mileage, or aim to get dupes of 1.5
          anniversary pilgrim, OR want to spend tickets/pulls on the possible
          Dorothy banner in the upcoming 1.5 Anniversary Event as well, we
          recommend you to <strong>SKIP</strong> Bay and put her in wishlist
          after her banner ends!
        </p>
        <p>
          Do not spend mileage on her! She is not worth mileage. As for dupes,
          Dupes are just for CP padding for Bay. Bay is a plug and play PVP
          Nikke, and she performs just fine at base. Giving her more dupes sure
          is nice because Bay's MLB lobby is top notch, but those 2% stat per
          dupe won't make your team suddenly become "stronger". After all, we
          can always use the event lobby screen where we all wish we were Poli.
        </p>
        <p>
          Remember: coupons are extremely valuable, and you don't want to miss
          out on the upcoming pilgrim, which will probably have a bigger
          influence on your account. Half anniversary is right after Bay!
        </p>
        <SectionHeader title="Kit Analysis & Highlights" />
        <h5>Three Cheers for HP Share!</h5>
        <blockquote>
          <p>
            ■ Activates if self survives when using Burst Skill. Affects all
            allies.
          </p>
          <p>Proportionally shares damage taken continuously.</p>
          <p>DEF ▲ 10.13% of caster's DEF continuously.</p>
        </blockquote>
        <p>This kit has 2 effects, which are:</p>
        <ul>
          <li>
            Increase Defense stat for all teammates. The value is horrible, and
            Defense itself is an underwhelming value in Nikke. It exists, it
            will reduce some damage, but it's not worth mentioning.
          </li>
          <li>
            Share damage taken among all 5 Nikkes. This is actually her
            strength, because you can protect your whole team with damage share,
            just like Jackal's S1. Requires Bay to burst to activate it. But
            wait, there's more!
          </li>
          <li>
            Bay will always receive 40% of incoming damage, with 60% is split
            among other 4. A normal ratio will be 40-15-15-15-15, compared to
            33-33-33 of Jackal. This way, her teammates will be much more
            resilient in exchange for more vulnerable Bay... except it's not a
            problem! It's because in Nikke, overkill damage won't spill onto
            others, that's why if your team receives one huge single target
            nuke, Bay will take most of it and die, but other 4 only receive 15%
            of that damage, further enhancing their survival capabilities by
            miles.
          </li>
          <li>
            The duration of this skill is eternal, as long as Bay's alive. If
            Bay is dead, the effect is ended immediately, until Bay's respawns
            AND USES HER BURST AGAIN. Yes, you must use Bay's burst to regain
            the damage share effect.
          </li>
          <li>
            This ratio is fixed, and can only change if the number of existing
            Nikke is reduced or there's another damage share effect. For
            example, Bay with 3/2/1 Nikke will have different ratio, which is
            displayed with this table:
          </li>
        </ul>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Bay's Teammate</th>
              <th>Bay's Share</th>
              <th>Teammate Share</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4</td>
              <td>40%</td>
              <td>15%</td>
            </tr>
            <tr>
              <td>3</td>
              <td>47.6%</td>
              <td>17.65%</td>
            </tr>
            <tr>
              <td>2</td>
              <td>57.17%</td>
              <td>21.43%</td>
            </tr>
            <tr>
              <td>1</td>
              <td>72.73%</td>
              <td>27.27%</td>
            </tr>
          </tbody>
        </Table>
        <Alert variant="primary">
          <p>
            Jackal/Anis/Poli also changes the ratio, but the effect is not
            really meaningful, and you just waste a slot, so unless you know
            exactly what you are doing, don't pair them together.
          </p>
        </Alert>
        <h5>Mistranslations and Damage Deviation</h5>
        <blockquote>
          <p>
            ■ Activates if self survives when using Burst Skill. Affects all
            allies.
          </p>
          <p>Proportionally shares damage taken continuously.</p>
          <p>■ Activates when Full Burst ends. Affects self.</p>
          <p>
            Constantly recovers Cover's HP by 2.88% of caster's final Max HP
            every 1 sec for 5 sec
          </p>
        </blockquote>
        <ul>
          <li>
            The first part of this skill is actually mis-translated. Bay will
            share damage with her OWN COVER whenever Bay herself bursts. It's
            the other part of her power which you can access even at skill lv 1.
          </li>
          <li>
            The ratio of sharing is 92.9% to cover, 7.1% to Bay, which is
            splitted to other 4 by the ratio 40-15-15-15. The final ratio when
            her cover is still intact is 92.9% (her cover) - 2.84% (Bay) - 1.06%
            (other 4).
          </li>
          <li className="highlight">
            Once again, overkill damage will never spill, that's why if Bay's
            cover has 1 HP and it receives 1 billion damage, the cover will
            break and all damage is lost. This is a very important concept and
            will be elaborated further below in the PVP section.
          </li>
          <li>
            Once the cover is broken, the damage share effect is also gone,
            immediately until cover is respawned by Biscuit's burst. At that
            point, you will need to burst Bay again to reapply the effect.
          </li>
          <li>
            Beside sharing damage with her own cover, skill 2 will also heal her
            cover by a not meaningful number, which we can ignore too.
          </li>
          <li>Requires Bay to burst to activate damage share.</li>
        </ul>
        <h5>Activation of Skills and Long Cooldowns</h5>
        <blockquote>
          <p>■ Affects self.</p>
          <p>Cover's Max HP ▲ 18% of the caster's Max HP, lasts for 20 sec.</p>
          <p>■ Affects all allies.</p>
          <p>Damage Taken ▼ 8.87% for 10 sec.</p>
        </blockquote>
        <ul>
          <li>
            Burst skill has an effect that heals your cover a bit, increases its
            tankiness which is kinda mid, but it's required to trigger her S1
            and S2. You have to burst Bay in order to access her damage share
            effect.
          </li>
          <li>If the cover is broken, burst skill won't "heal" it anymore.</li>
          <li>
            40 second cooldown on Burst, and a very small Damage Reduction buff
            are other highlights of this Burst skill.
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage Analysis" />
        <p>
          The ranks below have been adapted to our most recent tier list update.
          We'd encourage you to check if you haven't seen.
        </p>
        <h5>
          Campaign (LD) - <strong className="b">B</strong> | Campaign (HD) -{' '}
          <strong className="c">C</strong>
        </h5>
        <p>
          Bay is not expected to perform well in Campaign content. Her cover
          gets destroyed very fast and she does not recover her own cover either
          (like Biscuit can). In campaign stages, when 90% of all damage you
          take is going to cover, it will get destroyed in an instant. After Bay
          loses her cover, it is only a matter of time before she dies from
          another High Damage dealing rapture.
        </p>
        <h5>
          Boss (Solo) - <strong className="b">B</strong> | Boss (Adds) -{' '}
          <strong className="c">C</strong>
        </h5>
        <p>
          Niche use is the best tldr we can give you. The best comparison of Bay
          can be with Ade, who is also a niche character. Bay can be used when
          cover survivability is a serious issue, since she can heal cover and
          direct damage to her own cover as well! She also provides teamwide
          damage share, which can be useful for survival on some bosses who have
          one shot mechanics. The drawback is quite clear here, Bay is a 40s B2
          and needs to be paired with another B2 unit, which can cause unneeded
          problems. Not only that, she also does not provide any offensive
          support to the team, and is purely a defensive unit. Units that do
          both are more common in the current PvE Meta. However, some teams like
          the Classic SAnis buffer comp can make use of Bay.
        </p>
        <h5>
          PVP - <strong className="s">S</strong>
        </h5>
        <p>
          Before dwelling more into PVP, we need to remember the golden rule of
          Nikke:
        </p>
        <ul>
          <li>If the damage is overkill, it won't spill onto others.</li>
          <li>
            A 1 HP shield will block 1 billion damage single attack and the
            Nikke behind it receives 0 damage. That is applied the same with
            cover... or Nikke.
          </li>
        </ul>
        <p>Now, let's take a look again at Bay's damage share ratio:</p>
        <ul>
          <li>Cover: 92.9%</li>
          <li>Bay: 2.84%</li>
          <li>Nikke 1 = Nikke 2 = Nikke 3 = Nikke 4 = 1.06%</li>
        </ul>
        <p>
          What happens, when your team is under Scarlet burst, and Bay's cover
          exists?
        </p>
        <ul>
          <li>
            Cover receives: 92.9% x 500% of Scarlet burst = 464.5% of Scarlet
            burst. Cover dies, no spillover damage onto other Nikkes.
          </li>
          <li>Bay receives: 2.84% x 500% = 14.2% of Scarlet burst</li>
          <li>Other receives: 1.06% x 500% = 5.3% of Scarlet burst.</li>
        </ul>
        <Alert variant="primary">
          <p>
            Yep, Scarlet's burst, no matter how buffed she is, will deal close
            to 0 dmg when Bay bursts and her cover is intact. Due to the golden
            rule, even if her cover has 1 HP left, it will still consume 464.5%
            of Scarlet's burst damage, leaving all 5 Nikkes to receive almost 0
            damage.
          </p>
        </Alert>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="ZxfB-uSXkQI" />
          </Col>
        </Row>
        <p>
          When Bay's cover is broken, her protection against Nuke still exists
          but it's much weaker.
        </p>
        <ul>
          <li>
            Bay receives: 40% x 500% = 200% of Scarlet burst. She should die
            immediately.
          </li>
          <li>
            Other receives: 15% x 500% = 75% of Scarlet burst. This equals 25%
            damage reduction, and it depends on a lot of factors to see whether
            this damage will kill your team or not.
          </li>
        </ul>
        <Alert variant="primary">
          <p>
            Keeping Bay's cover alive before the nuke is the most essential part
            of her power. That's why you should keep Bay in a safer position,
            like P3 P4 or P5 in PVP to avoid splash damage from other Rocket
            Launcher that could destroy your cover before the nuke lands.
          </p>
        </Alert>
        <p>
          Bay is very good against instant Nuke, but she performs okayish only
          against DPS types. That's because her cover can swallow one HUGE
          instance of damage, but a lot of small instances will break it easily,
          exposing her team. Luckily, you can still pair her with a decent
          protector B1 like Noise, or Moran.
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="0Bj24PNrYIg" />
          </Col>
        </Row>
        <p>
          In this clip, you can see although Bay's cover was destroyed pretty
          quick, due to Noise's Max HP up and Bay's damage share, our Sanis was
          alive long enough to tank through 3s invulnerable of Noah, then
          proceed killing other Sanis instead.
        </p>
        <p>
          Bay hates the most, is the type of attack that either hits both cover
          and herself, or takes a while to charge up. For example, Emilia's
          burst or Snow White's burst. Those attacks not only destroy her cover
          FIRST then body, but also deal huge damage, which could lead to a team
          wipe or very low hp. Snow White's burst takes 5 seconds to charge up,
          giving her team time to destroy bay's cover since it is taking ~93% of
          incoming damage!
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="ou434od9-E0" />
          </Col>
        </Row>
        <p>To summary it up, Bay is:</p>
        <ul>
          <li>Good against Nuke.</li>
          <li>Okay against DPS.</li>
          <li>
            Weak against splash of Emilia or Single Target nuke of Snow White.
          </li>
        </ul>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          As a Defender, gear and OL are not really important to her (because
          eventually, all gears will contribute too little compared to stat gain
          per level, and OL %Def sucks). You can OL her for some CP padding if
          you like.
        </p>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 1-4</strong>
          </li>
          <ul>
            <li>
              Her scaling is better than average, but the problem is, 99% of her
              power is the damage share effect and you can access it fully even
              at level 1. Her other effect is not impactful at all. We highly
              recommend keep this skill at lv 1, unless you want to stack CP on
              her to avoid a penalty/trigger penalty on your opponent.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 1-4</strong>
          </li>
          <ul>
            <li>
              Actually for the same reason as above. You unlock damage share
              effect at level 1, no point investing in this skill further than 4
              beside CP padding.
            </li>
          </ul>
          <li>
            <strong>Burst: 1-4</strong>
          </li>
          <ul>
            <li>
              We hate to repeat the same sentence, but yeah, keep this burst at
              max 4, even for CP Padding.
            </li>
          </ul>
        </ul>
        <p>
          <strong>Suggested Progression Path</strong>
        </p>
        <div className="nikke-skills-tips">
          <div className="nikke-skills-row header">
            <div className="column character">&nbsp;</div>
            <div className="column suggestions pve">PVE Investment</div>
            <div className="column suggestions pvp">PVP Investment</div>
            <div className="column priority">Priority</div>
          </div>
          <div className="nikke-skills-row data">
            <NikkeSkill char="bay" />
          </div>
        </div>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_8_sm.png"
          alt="Guide"
        />
        <p>
          Since Bay is a Defender with damage share effect, the only cube for
          her will be Vigor - which increases her Max HP, allowing her to soak
          even more damage.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1: Classic Summer Anis Last Bullet Comp</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="bay" enablePopover />
            </div>
          </div>
        </div>
        <p>
          If keeping Privaty alive is a problem, since attackers don't receive a
          lot of heal from Biscuit: With Bay's damage share, the B3 should be
          able to live. While it is a damage loss to use Bay over the likes of
          Poli etc., it is a QoL improvement for the team, making it easier to
          survive 3 minutes.
        </p>
        <SectionHeader title="PVP Team Compositions" />
        <h5>Team #1: Common Bay Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
              <NikkeCharacter mode="icon" slug="moran" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="bay" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
        </div>
        <p>
          This team has 2 layers of protection: Noise or Moran provides the
          first layer, then Bay soaks damage. It's a 3 RL team, making it very
          hard to kill even with 3 RL Scarlet. And 2 RL Scarlet will never be
          able to kill 3 RL Noise team. The last B3 can be up to your choice,
          which can be:
        </p>
        <ul>
          <li>SAnis,</li>
          <li>Scarlet: Black Shadow,</li>
          <li>
            Scarlet. This one is actually interesting, due to the damage share
            effect, this whole team will turn into a Thornmail instead, with
            each nikke providing a 30% chance to trigger Scarlet's S2 on being
            hit as long as Bay is alive.
          </li>
        </ul>
        <h5>Team #2: Stall team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="moran" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="bay" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="quiry" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team relies on a huge amount of damage reduction and healing to
          tank through any type of DPS through to your face, while slowly builds
          up the damage dealt by 5 RL. It's super annoying, although xAnne will
          give them a hard time due to healing potency debuff.
        </p>
        <h5>Team #3: Stall team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="quiry" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="bay" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Enjoy watching this video to have a visual explanation on how it
          works!
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="nfBjudEtlGg" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>PVP</p>
          </div>
        </div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>All powers are unlocked at level 1.</li>
                  <li>
                    Provide strong protection to the team in PVP - can even
                    block instant AoE nukes like Scarlet's!
                  </li>
                  <li>Damn pretty and so sweaty (ˆڡˆ)!</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Relies on cover to block nukes.</li>
                  <li>
                    Very vulnerable to high single target pierce damage, splash
                    damage, or nukes that take time to charge up.
                  </li>
                  <li>
                    Her skills, besides the damage share effect, provide little
                    to nothing else due to incredibly low values.
                  </li>
                  <li>
                    Her performance is very niche in PVE, and only in Bosses.
                    She does not have a place in campaign.
                  </li>
                  <li>
                    Her leg-raising anatomy is questionable :/ (Please fix Shift
                    Up)
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesBay;

export const Head: React.FC = () => (
  <Seo
    title="Bay guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Bay in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
